<template>
  <Content>
    <PersonalityCarousel
      :redirect-path="'/share-result'"
      :info-page="false"
      :slides="slides"
      @changeSlide="changeSlide($event)"/>
  </Content>
</template>

<script>
import PersonalityCarousel from '@components/PersonalityCarousel/PersonalityCarousel.vue';
import trust1 from '@assets/trust-1.svg';
import trust2 from '@assets/trust-2.svg';
import trust3 from '@assets/trust-3.svg';

export default {
  name: 'TrustExplain',
  components: { PersonalityCarousel },
  data: () => ({
    trust1,
    trust2,
    trust3,
    slides: [
      {
        id: 0,
        src: trust1,
        title: 'WE GET IT!',
        type: 'usual',
        desc: 'It might seem scary. What will they say?',
        textButton: 'TAP TO CONTINUE',
      },
      {
        id: 1,
        src: trust2,
        title: 'DON’T WORRY...',
        type: 'usual',
        desc: 'We are only asking about your strengths!',
        textButton: 'TAP TO CONTINUE',
      },
      {
        id: 2,
        src: trust3,
        title: 'AND NO...',
        type: 'usual',
        desc: 'They won’t see your score',
        textButton: 'TAP TO CONTINUE',
      },
    ],
    currentSlide: 0,
  }),
  methods: {
    changeSlide(numberSlide) {
      this.currentSlide = numberSlide;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;
  font-style: $newDefaultFont;
}
</style>
